/* .modal-dialog {
  max-width: 850px !important;
} */
/* .invalid-feedback {
  display: inline !important;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
} */

.ant-steps + div {
  line-height: inherit !important ;
  text-align: inherit !important;
  color: inherit !important;
  background-color: inherit !important;
  border-radius: inherit !important;
  border: inherit !important;
  margin-top: inherit !important;
}

.ant-select-multiple.ant-select-lg.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-lg.ant-select-allow-clear .ant-select-selector {
  padding-inline-end: 150px;
}

ul.nav.nav-tabs {
  margin-left: 15px;
}

.page-item.active .page-link {
  background: transparent
    linear-gradient(46deg, var(--primary-color) 0%, #8600fe 100%);
}

.global-loader-show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(255 255 255); */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.global-loader-hide {
  display: none;
}

.radio-container {
  flex-direction: column;
  gap: 10px;
  font-family: Arial, sans-serif;
}

.radio-container label {
  position: relative;
  cursor: pointer;
}

.vertical-menu {
  overflow: auto;
  width: 240px;
  /* z-index: 1001; */
  background: #fff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.ant-select {
  width: 100%;
}

.ant-select-dropdown {
  z-index: 99999;
}

.upload-button input[type="file"] {
  display: none;
}

.upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #c8c3c3;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

/* Hover effect for the custom button */
.upload-button:hover {
  background-color: #aaa6a6;
}

.ant-message {
  z-index: 99999999999999;
}

.page-item.active .page-link {
  border-radius: 17px;
}

tr.ant-table-placeholder {
  z-index: 999 !important;
}

.pac-container.pac-logo {
  z-index: +9999 !important;
}
span.tbl-column {
  max-height: 40px;
  overflow: hidden;
  display: block;
  max-width: 200px;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  vertical-align: top;
}

.timepicker {
  z-index: 9999;
}
.timePicker-2 {
  width: 100%;
}

@media (min-width: 992px) {
  .file-size-large .modal-lg {
    --bs-modal-width: 1000px !important;
  }
}

.file-size-large .edit-modal .col-md-2 {
  width: 10%;
}

/* .file-size-large .edit-modal .col-md-3 {
  width: 22.5%;
} */

.file-size-large .edit-modal .col-md-12 {
  width: 90%;
}

.ant-notification {
  z-index: 999999;
}

::-webkit-scrollbar {
  width: 2px;
  height: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #17345e;
  border-radius: 10px;
}

#layout-wrapper {
  /* display: grid;
  grid-template-columns: 400px 1fr;
  column-gap: 50px; */
}

.transaction-approve {
  display: flex;
  align-items: center;
  margin: 10px;
}
.transaction-approve button {
  border: 0px;
  background: none;
}

.feedback-desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 200px;
}

.notification-table .ant-spin-nested-loading {
  width: 100%;
}
.notification-table .ant-table-wrapper {
  display: flex;
  width: 100%;
}

.ant-upload-list-item-action {
  opacity: 1 !important;
  background: #fff !important;
  color: white !important;
  margin-top: 3px !important;
  min-width: 30px !important;
  min-height: 30px;
  border-radius: 50%;
}

.ant-upload-list-item-action svg {
  fill: red !important;
}

.drop-off-detail {
  height: 40px !important;
  overflow: auto !important;
}

/* Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.privacy-term p {
  text-align: justify;
}

.ant-upload-list{
max-width: 200px !important;
}

.img-list{
  display: flex;
  flex-wrap: wrap;
  
}

.img-list li{
  margin-right: 20px;
  list-style: disc;
  list-style-position: inside;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #757575 !important;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #757575 !important;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #757575 !important;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #757575 !important;
}
.ant-table-wrapper td ol, .ant-table-wrapper td ul {  padding: 0 0 0 20px !important;}