@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary-color: #5503f3;
  --secondary-color: #8000fd;
  --yellow-color: #eeb704;
  --red-color: #ea4643;
  --orange-color: #ff7b29;
  --black-color: #000000;
  --white-color: #fff;

  --primary-color-background: #f6f9ff;
  --primary-secondary-background: #f9fbf9;
  --sub-font: #798392;
  --menu-font: #798f79;
  --header-font: #fff;
  --color-dadfda: #dadfda;
  --primary-title: #2a2b2f;
  --from-border-color: #dcdcdc;
  --primary-text-color: #656a72;
  /*--header-font: #fff;
  --sub-font: #798392;
  --light-text: #BCBCBC;
  --menu-font: #798F79;*/

  /* font-family */
  --primary-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
