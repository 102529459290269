/* @import "assets/css/variables.css"; */
/* @import "assets/css/variables.css"; */
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: var(--primary-font-family);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  text-align: left;
  background-color: #f1f5f7;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: #505d69;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.invalid-feedback {
  display: block;
  font-size: 12px;
  font-family: var(--primary-font-family);
  font-weight: 500;
  color: #ff3d60;
}

/* header */
header#page-topbar {
  height: 50px;
  z-index: 1001;
  background-color: var(--primary-color-background);
  box-shadow: 0px 2px 6px #474b5024;
}

.navbar-header {
  padding: 13px calc(40px / 2) 13px 13px;
  height: 50px !important;
}

.navbar-header .dropdown .header-item {
  overflow: inherit;
  padding: 0px;
  display: block;
  position: relative;
  border: none;
  min-height: inherit;
  min-width: inherit;
  height: auto;
  background: none !important;
}

.navbar-header .dropdown:not(:last-child) {
  margin-right: 20px;
  height: 20px;
  line-height: normal;
}

.navbar-header .dropdown .header-item img {
  display: block;
  height: auto;
  width: auto;
  padding: 0px;
  background: none;
}

.navbar-header .dropdown .notification-icon .header-notification {
  width: 21px;
}

.navbar-header .page-title-box h1 {
  margin: 0px;
  font-size: 16px;
  line-height: 18px;
}

.navbar-header .dropdown.user-dropdown {
  padding-left: 20px;
  border-left: 1px solid #bebebe;
}

.navbar-header .dropdown.user-dropdown .header-item {
  padding-right: 12px;
}

.notification-count {
  right: -1px;
  top: -3px;
  border: 1px solid #656a72;
  border-radius: 60px;
  height: 12px;
  width: 12px;
  display: block;
  font-size: 9px;
  line-height: 10px;
  font-family: var(--primary-font-family);
  font-weight: 600;
}

.notify {
  animation: bell 1.5s ease-in-out infinite;
  transform-origin: center top;
}

.down-arrow {
  background-repeat: no-repeat;
}

.user_icon {
  font-size: 19px;
  color: var(--primary-color);
}

.navbar-header .dropdown .header-item {
  display: flex;
  height: 24px;
  background: none !important;
  align-items: center;
}

@keyframes bell {
  0% {
    transform: rotate(35deg);
  }

  12.5% {
    transform: rotate(-30deg);
  }

  25% {
    transform: rotate(25deg);
  }

  37.5% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(15deg);
  }

  62.5% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* vertical-menu  */

.vertical-menu {
  width: 75px;
  z-index: 1002;
  bottom: 0;
  margin-top: 0;
  padding-top: 10px;
  background-color: var(--primary-color-background);
  box-shadow: 1px 0px 6px #474b5024;
}

.vertical-menu .navbar-brand-box {
  margin-bottom: 18px;
  width: auto;
  padding: 0px;
}

.vertical-menu .logo-dark {
  text-decoration: none;
}

.vertical-menu .logosm img {
  width: 40px;
  border-radius: 200px;
  display: block;
  margin: 0 auto 0;
  height: auto;
  padding: 5px 0 0 0;
}

.vertical-menu .logosm label {
  line-height: normal;
  margin-bottom: 0px;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: var(--primary-color);
  font-weight: 500;
}

.vertical-menu #sidebar-menu {
  padding: 0px;
}

.vertical-menu .vertical-menu #sidebar-menu .metismenu {
  margin-bottom: 0px;
}

.vertical-menu #sidebar-menu .metismenu li {
  padding: 12px 5px;
}

/* .vertical-menu #sidebar-menu .metismenu li:after{content:""; background-image:url(../icons/menu-effect.svg);} */
.vertical-menu #sidebar-menu .metismenu li:not(:last-child) {
  border-bottom: 1px solid var(--color-dadfda);
}

.vertical-menu #sidebar-menu .metismenu li a {
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  padding: 0px;
  overflow: initial;
  justify-content: center;
}

.vertical-menu #sidebar-menu .metismenu li label {
  width: 100%;
  margin: 0px;
  cursor: pointer;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: var(--menu-font);
  font-family: var(--primary-font-family);
}

.vertical-menu #sidebar-menu .metismenu li .svg-icon {
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}

.vertical-menu #sidebar-menu .metismenu li.active .svg-icon,
.vertical-menu #sidebar-menu .metismenu li:hover .svg-icon {
  transition: all 0.2s ease-in-out;
  background: transparent
    linear-gradient(46deg, var(--primary-color) 0%, #8600fe 100%);
}

.vertical-menu #sidebar-menu .metismenu li.active label,
.vertical-menu #sidebar-menu .metismenu li:hover label {
  color: var(--primary-color);
}

.vertical-menu #sidebar-menu .metismenu li .svg-icon svg {
  fill: var(--menu-font);
}

.vertical-menu #sidebar-menu .metismenu li .svg-icon.stroke-icon svg {
  stroke: var(--menu-font);
}

.vertical-menu #sidebar-menu .metismenu li.active .svg-icon svg,
.vertical-menu #sidebar-menu .metismenu li:hover .svg-icon svg {
  fill: var(--bs-white);
}

.vertical-menu #sidebar-menu .metismenu li.active .svg-icon.stroke-icon svg,
.vertical-menu #sidebar-menu .metismenu li:hover .svg-icon.stroke-icon svg {
  stroke: var(--bs-white);
}

/* start main content  */
.main-content {
  margin-left: 75px;
  overflow: hidden;
  padding-top: 0;
  height: 100%;
  min-height: 100vh;
  background: #fff;
}

.page-content {
  padding: calc(50px + 36px) calc(16px / 2) 75px calc(16px / 2);
}

/* card and card title */
.card:not(:last-child) {
  margin-bottom: 30px;
}

.card {
  background: var(--primary-secondary-background) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #474b5024;
  border-radius: 5px;
  border: 0px;
}

.card-primary-color {
  background: var(--primary-color-background);
}

.card .card-body {
  padding: calc(var(--bs-card-spacer-y) + 14px)
    calc(var(--bs-card-spacer-x) + 14px);
  width: 100%;
}

.pageTitle h1 {
  margin: 0px;
  font-family: var(--primary-font-family);
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0px;
  color: var(--primary-title);
}

/* Card Title */
h2.card-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  font-family: var(--primary-font-family);
  color: var(--primary-title);
}

h2.card-title strong {
  font-weight: 500;
}

h5.card-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  font-family: var(--primary-font-family);
  color: var(--primary-title);
}

h5.card-title strong {
  font-weight: 500;
}

/* Table UI */
.table-responsive .table {
  border: none;
  margin: 0px;
}

.table-responsive .table thead {
  position: relative;
}

.table-responsive .table thead:after {
  content: "";
  background: #858b97;
  box-shadow: 0px 2px 10px #70ab3a24;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
}

.table-responsive .table thead tr {
  position: relative;
  z-index: +99;
}

.table-responsive .table thead th {
  padding: 16px 8px;
  background: no-repeat;
  border: 0px;
  color: var(--header-font);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-family: var(--primary-font-family);
  position: relative;
  z-index: +99;
}

.table-responsive .table tr:not(:last-child) {
  border-bottom: 1px solid rgba(000, 000, 000, 0.1);
}

.table-responsive .table tr:first-child {
  border-top: 0px;
}

.table-responsive .table tr:last-child {
  border: 0px;
}

.table-responsive .table td {
  padding: 10px 8px;
  background: no-repeat;
  border: 0px;
  letter-spacing: 0.13px;
  color: var(--sub-font);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--primary-font-family);
}

/*****  start Switch *****/
.form_switch {
  position: relative;
  margin: 0px;
  width: 34px;
  height: 19px;
}

.form_switch .form_check_input {
  width: 100%;
  height: 100%;
  z-index: +99;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.form_switch .switch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-dadfda);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 26px;
}

.form_switch .switch_slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  top: 2px;
  background-color: var(--bs-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0px 2px 2px #0000003d;
  border: 0.5px solid #00000000;
}

.form_switch input:checked + .switch_slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(14px);
  transform: translateX(13px);
}

.form_switch input:checked + .switch_slider {
  background-color: #6cc975;
}

/***** End Switch *****/

/* Form Field Control */
.form-control,
.DateRangePickerInput,
.ant-select-multiple.ant-select-lg .ant-select-selector {
  overflow: hidden;
  text-overflow: ellipsis;
  word-spacing: normal;
  padding: 10px;
  background-color: var(--bs-white);
  border: 1px solid var(--from-border-color);
  border-radius: 8px;
  letter-spacing: 0px;
  color: var(--primary-text-color);
  font-family: var(--primary-font-family);
  font-size: 12px;
  line-height:12px;
  font-weight: 400;
  height: 35px;
  max-height: 35px;
  outline: none;
}

.DateRangePickerInput {
  padding: 0;
  overflow: visible;
}

.form-group .DateRangePickerInput {
  width: 100%;
}

.DateRangePicker {
  width: 100%;
}

.DateRangePicker .DateInput {
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  color: var(--primary-text-color);
  margin: 0;
  padding: 0;
  background: none;
}

select.form-control {
  padding: 0 10px;
}

textarea.form-control {
  height: 100px;
  max-height: 100px;
  padding: 5px 10px;
  resize: none;
}

.form-label {
  font-size: 12px;
  color: #000;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

/* Button Control */
button.btn {
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  font-size: 14px;
  font-family: var(--primary-font-family);
  min-height: 40px;
  min-width: 125px;
  border: 0px;
  box-shadow: none;
}

button.btn-primary {
  background: transparent
    linear-gradient(46deg, var(--primary-color) 0%, #8600fe 100%);
}

button.btn-primary:hover {
  transition: all 0.3s ease-in-out;
  background: transparent
    linear-gradient(-46deg, var(--primary-color) 0%, #8600fe 100%);
  color: #fff !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:first-child:active,
:not(.btn-check) + .btn:active {
  border: 0px;
  box-shadow: none;
}

/* Page Filter */
.page-right-filter .btn {
  box-shadow: 0px 3px 6px #0000001a;
  font-size: 12px;
  line-height: 20px;
  min-height: 35px;
  font-family: var(--primary-font-family);
}

/* select-selection */
.ant-select-single.ant-select-lg {
  font-size: 12px;
  height: 35px;
  width: 200px;
  font-family: var(--primary-font-family);
}

.statusFilter .ant-select-selection-placeholder {
  font-size: 12px !important;
  color: var(--primary-text-color);
  font-family: var(--primary-font-family);
  font-weight: 400;
}

.ant-select-dropdown .ant-select-item,
.ant-select-single.ant-select-lg .ant-select-selector {
  font-size: 12px;
  font-family: var(--primary-font-family);
}

.statusFilter .ant-select-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.statusFilter .ant-select-arrow span {
  opacity: 0;
}

.statusFilter .ant-select-selector:after,
.dropdown-toggle::after {
  display: none !important;
}

.statusFilter .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  background: transparent
    linear-gradient(46deg, var(--primary-color) 0%, #8600fe 100%);
  height: 28px;
  line-height: 28px;
  border-radius: 4px;
}

.statusFilter
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item
  .ant-select-selection-item-content {
  font-family: var(--primary-font-family);
  font-weight: 500;
  font-size: 13px;
  color: #fff;
}

.statusFilter .ant-select-multiple.ant-select-lg .ant-select-selector {
  border-radius: 6px !important;
  border: 1px solid var(--from-border-color) !important;
  box-shadow: none !important;
  padding-right: 25px;
  min-width: 151px !important;
}

.statusFilter
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  color: #fff;
  font-size: 12px;
  border-left: 1px solid #ffffff91;
  padding-left: 3px;
}

.statusFilter .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 34px;
  line-height: 34px;
}

.statusFilter
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item
  .ant-select-selection-item-content {
  font-size: 15px;
}

.statusFilter .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}

.statusFilter .ant-select-multiple.ant-select-lg .ant-select-selector {
  border-radius: 8px !important;
  border: 1px solid var(--from-border-color) !important;
  box-shadow: none !important;
  padding-right: 25px;
  min-width: 151px !important;
}

.statusFilter .ant-select-multiple.ant-select-lg .ant-select-selector {
  padding: 4px;
}

.statusFilter
  .ant-select-multiple.ant-select-lg
  .ant-select-selector
  .ant-select-selection-item {
  height: 23px;
  line-height: 21px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-overflow-item {
  max-width: 75%;
}

.statusFilter
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-item
  .ant-select-selection-item-content {
  font-size: 12px;
  font-weight: 400;
}

.statusFilter45 .ant-select-selection-placeholder {
  font-size: 15px;
}

/* modal UI control */
.modal-header .modal-title {
  font-family: var(--primary-font-family);
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0px;
  color: var(--primary-title);
}

.modal-header {
  border-bottom: 1px solid rgba(000, 000, 000, 0.1) !important;
}

.modal-header .close {
  opacity: 1 !important;
}

.modal-header .close span {
  font-size: 0px;
  width: 20px;
  height: 20px;
  display: block;
  background-image: url(./assets/icons/close.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-body .form-group label {
  font-size: 12px;
  color: #000;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.modal-body .form-group .invalid-feedback {
  font-size: 11px;
  line-height: 14px;
}

.modal-body .form-group button.btn,
.modal-body button.ant-btn {
  background: transparent
    linear-gradient(46deg, var(--primary-color) 0%, #8600fe 100%);
  border-radius: 8px;
  box-shadow: none;
  font-size: 15px;
  color: #fff !important;

  transition: all 0.3s ease-in-out;
  font-weight: 500;
  font-size: 16px;
  font-family: var(--primary-font-family);
  min-height: 40px;
  min-width: 125px;
  border: 0px;
}

.modal-body .form-group button.btn:hover,
.modal-body button.ant-btn:hover {
  background: transparent
    linear-gradient(-46deg, var(--primary-color) 0%, #8600fe 100%) !important;
  color: #fff !important;
}

/* formTable */
.formTable .table thead th {
  padding: 12px 8px;
  font-size: 13px;
}

.formTable .table td {
  font-size: 12px;
}

.formTable .table td {
  vertical-align: middle;
}

/* form-check */
.form-check-input {
  box-shadow: none !important;
  cursor: pointer;
  border-color: var(--from-border-color) !important;
}

.form-check-input:checked {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color);
}

.form-check-box input {
  margin-top: 0px;
  float: none !important;
}

.form-check-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-check-box .form-check-label {
  margin-left: 8px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

/*****  start Radio box *****/
.radio_label .radio-box {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  display: inline-block;
}

.radio_label .radio-box span {
  display: inline-block;
  padding-left: 30px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.radio_label .radio-box span:after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  background: #ffff;
  border-radius: 60px;
  border: 1px solid var(--from-border-color);
}

.radio_label .radio-box span:before {
  content: "";
  width: 12px;
  height: 12px;
  display: inline-block;
  position: absolute;
  left: 4px;
  top: 4px;
  background: #cecece;
  border-radius: 60px;
  z-index: 9;
  opacity: 0;
}

.radio_label .radio-box input {
  position: absolute;
  width: 20px;
  left: 0px;
  opacity: 0;
  z-index: +9999;
  height: 20px;
  cursor: pointer;
}

.radio_label .radio-box input[type="radio"]:checked + span:before {
  opacity: 1;
  background-color: var(--primary-color);
}

.radio_label .radio-box input[type="radio"]:checked + span:after {
  border-color: var(--primary-color);
}

.radio_label .radio-box {
  margin-right: 15px;
  margin-bottom: 10px;
}

.radio_label .radio-box:last-child {
  margin-right: 0px;
}

/***** End Radio kbox *****/
.ant-upload-wrapper .ant-btn,
.ant-upload {
  width: 100%;
  height: 40px;
}

.w-70 {
  width: 70px;
}

.vertical-menu #sidebar-menu .metismenu li.active .svg-icon i,
.vertical-menu #sidebar-menu .metismenu li:hover .svg-icon i {
  color: #fff;
}

#sidebar-menu ul li a i {
  opacity: 1;
}

.spinner-border {
  margin: auto;
  display: block;
}

button.btn-primary.resend-btn {
  color: var(--bs-white) !important;
  line-height: 28px !important;
  padding: 0 10px !important;
  height: auto !important;
  margin-left: 10px !important;
  font-size: 13px !important;
  min-height: inherit !important;
  min-width: inherit !important;
}

.plus-btn {
  margin-top: 40px;
  vertical-align: top;
  display: inline-block;
}

.border-bottom {
  border-bottom: 1px solid rgba(000, 000, 000, 0.1);
  margin-bottom: 1rem;
}

.modal {
  padding: 0 !important;
}

.edit-modal .col-md-4 {
  width: 30%;
}

.edit-modal .col-md-2 {
  width: 10%;
}

@media (max-width: 768px) {
  .edit-modal .col-md-4 {
    width: 100%;
  }

  .edit-modal .col-md-2 {
    width: 100%;
  }

  .plus-btn {
    margin-top: 0;
  }
}

/***** Start Upload button css *****/

label.file-label {
  display: block;
  width: 100%;
  position: relative;
}

label.file-label input[type="file"] {
  line-height: 22px;
  position: relative;
  z-index: 1;
  background: none;
  padding: 5px 10px;
}

label.file-label .file-custom {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  border: 0.5px solid #8600fe;
  line-height: normal;
  padding: 3px 8px;
  border-radius: 3px;
  color: #8600fe;
  background: #fff;
  z-index: 0;
}

input[type="file"]::file-selector-button {
  display: none;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control[type="file"] {
  overflow: hidden;
}

.radio-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.radio-container label {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: auto;
}

.ant-select {
  max-width: 100%;
}

.maxwidth-100 .ant-select {
  max-width: 100%;
}

.ant-message {
  z-index: 99999999999999;
}

/*  */

.vertical-menu {
  width: 240px !important;
  background: var(--primary-color-background) !important;
}

.main-content,
footer.footer,
header#page-topbar {
  margin-left: 239px;
}

.vertical-menu #sidebar-menu .metismenu li {
  padding: 10px;
  border-radius: 5px;
}

.vertical-menu #sidebar-menu .metismenu li a {
  flex-wrap: nowrap;
  align-items: center;
  text-align: left;
}

.vertical-menu #sidebar-menu {
  padding: 0 20px;
}

.vertical-menu #sidebar-menu .metismenu li .svg-icon {
  margin-bottom: 0px;
  width: auto;
  height: auto;
  margin-right: 10px !important;
}

.vertical-menu #sidebar-menu .metismenu li label {
  font-size: 14px;
}

.vertical-menu #sidebar-menu .metismenu li:not(:last-child) {
  border: 0px;
  margin-bottom: 10px;
}

.vertical-menu #sidebar-menu .metismenu li.active .svg-icon,
.vertical-menu #sidebar-menu .metismenu li:hover .svg-icon {
  color: #fff;
  background: none;
}

.vertical-menu #sidebar-menu .metismenu li.active,
.vertical-menu #sidebar-menu .metismenu li:hover {
  background: transparent
    linear-gradient(46deg, var(--primary-color) 0%, #8600fe 100%);
}

.vertical-menu #sidebar-menu .metismenu li.active label,
.vertical-menu #sidebar-menu .metismenu li:hover label {
  color: #fff;
}

.vertical-menu #sidebar-menu .metismenu li .svg-icon svg {
  font-size: 15px;
}

.vertical-menu .logosm label {
  font-size: 34px;
  line-height: 60px;
  font-weight: 600;
}

.vertical-menu .navbar-brand-box {
  box-shadow: 0px 2px 6px #474b5024;
  margin-top: -10px;
  height: 60px;
}

.navbar-header {
  height: 60px !important;
}

header#page-topbar {
  height: 60px;
}

/*************Table style start******************/

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #8600fe;
  color: #fff;
  white-space: nowrap;
}

.ant-table-wrapper .ant-table-thead > tr > th:not(:first-child) {
  min-width: 100px;
}

.scrollbar-table .ant-table-content {
  overflow: auto !important;
}

/* width */
.scrollbar-table .ant-table-content::-webkit-scrollbar {
  height: 5px !important;
}

/* Track */
.scrollbar-table .ant-table-content::-webkit-scrollbar-track {
  background: rgba(125, 101, 159, 0.13) !important;
}

/* Handle */
.scrollbar-table .ant-table-content::-webkit-scrollbar-thumb {
  background: #8600fe !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
}

.ant-table-wrapper td ul,
.ant-table-wrapper td ol {
  margin: 0;
  padding: 0;
}

.page-item.active .page-link {
  margin: 0 5px !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
  line-height: 1.3;
}

.ant-table-wrapper td br {
  display: none;
}

span.tbl-column {
  max-height: 45px;
}

.dropdown-item {
  font-size: 14px;
}

.page-item.disabled .page-link {
  font-size: 12px;
}

.page-item .page-link {
  font-size: 12px;
}

.dataTables_info {
  font-size: 12px;
}

.ql-clipboard {
  left: 0;
  height: 1px;
  overflow-y: visible;
  position: relative;
  top: 0;
}

.ql-snow .ql-tooltip {
  position: relative;
  transform: none;
}

.card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.modal-header {
  background: #0000 linear-gradient(46deg, var(--primary-color) 0, #8600fe 100%);
  border-bottom: 1px solid #0000001a !important;
}

.modal-header .modal-title {
  color: var(--header-font);
  font-family: var(--primary-font-family);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}

.modal-header .close span {
  filter: brightness(0) invert(1);
}

.row {
  width: calc(100% + 24px);
}

.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
  background: #8600fe !important;
  border-color: #8600fe !important;
}

.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: #c99af4 !important;
  border: 1px double #c99af4 !important;
  color: #8600fe !important;
}

.CalendarDay--hovered-span:active,
.CalendarDay--after-hovered-start:active {
  background: #c99af4 !important;
}

.DateInput__display-text--focused {
  background: #c99af4 !important;
  border: 1px double #c99af4 !important;
  color: #8600fe !important;
}

.CalendarDay--selected-span {
  background: #8600fe !important;
  border-color: #8600fe !important;
}

.anticon-plus.form-control,
.anticon-minus-circle.form-control {
  padding: 2px;
  border-color: var(--primary-color);
  path {
    fill: var(--primary-color);
  }
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

ul.nav.nav-tabs {
  margin: 0;
  padding: 0;
}

.nav-tabs .nav-link {
  border: solid 1px #8600fe;
  border-radius: 8px;
  margin: 0 10px 10px 0;
  font-size: 12px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  background: transparent
    linear-gradient(-46deg, var(--primary-color) 0%, #8600fe 100%);
  color: #fff !important;
}

.modal-body .container {
  padding: 0;
}

.scrollbar-table,
.page-filters {
  padding: 0;
}

.checkbox-span .invalid-feedback {
  margin: 0;
}

.mt-22 {
  margin-top: 22px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  font-size: 12px;
}

.ant-select-single .ant-select-selector {
  color: var(--primary-text-color);
  font-size: 12px;
}

.modal-body input[type="checkbox"],
.modal-body input[type="radio"] {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.logo-mobile {
  position: fixed;
  z-index: 9999;
  width: 50px;
  left: 24px;
  top: 0;

  img {
    width: 35px;
    height: auto;
    padding: 5px 0 0 0;
  }
}

.toggle_btn.toggle-mobile {
  position: fixed;
  z-index: 99999;
  top: 15px;
  left: 90px;
  padding: 0;
  width: 32px;
  height: 32px;
  background-image: url(assets/images/nav-open.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px auto;
  border-color: #8600fe !important;
}

.active .toggle_btn.toggle-mobile {
  background-image: url(assets/images/nav-close.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px auto;
}
.col-2.mt-22 {
  width: auto;
}
.pb-60 {
  padding-bottom: 60px;
}
.borderBox {
  border: 1px solid var(--from-border-color);
  width: 100%;
  padding: 15px;
  border-radius: 8px;
}
.borderBox + .borderBox {
  margin-top: 10px;
}

@media (min-width: 993px) {
  .logo-mobile,
  .toggle_btn.toggle-mobile {
    display: none;
  }
}

@media (max-width: 992px) {
  .main-content,
  footer.footer,
  header#page-topbar {
    margin-left: 0;
  }

  .vertical-menu {
    position: fixed;
    top: 50px !important;
    left: 0px;
    z-index: 9999;
    height: 100%;
    background: var(--white) 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex !important;
    flex-wrap: wrap;
    align-content: space-between;
    border-right: 1px solid #e6e4f0;
    left: -100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 30px 0 0 0;
  }

  .active .vertical-menu {
    left: 0;
  }

  .vertical-menu .navbar-brand-box {
    display: none;
  }
  .modal-backdrop {
    z-index: 999999;
  }
  .modal-open .modal {
    z-index: 999999;
  }
}

@media (max-width: 767px) {
  .card .card-body {
    padding: 15px;
  }
  .col-6 {
    width: 100%;
  }

  .dataTables_info {
    text-align: center;
    margin-bottom: 10px;
  }

  .pagination {
    width: 100%;
    justify-content: center;
  }

  .footer .col-sm-6 {
    width: 50%;
  }
  .copyright {
    display: block !important;
    text-align: right;
    font-size: 12px;
  }
  .footer .container-fluid {
    padding: 0;
  }
  body .navbar-header .dropdown .dropdown-menu {
    left: auto !important;
    right: 10px !important;
    width: 200px;
  }
  body .page-content {
    padding-top: 0;
  }
  .page-title-box {
    flex-wrap: wrap;
  }
  .page-right-filter {
    margin-top: 10px;
    flex-wrap: wrap;
    width: 100%;
  }
  .page-right-filter > div,
  .page-right-filter > button {
    margin-top: 10px;
  }

  .page-content .container-fluid {
    padding: 0;
  }
  footer.footer {
    padding: 20px 12px;
  }
  .logo-mobile {
    left: 12px;
  }
  .card:not(:last-child) {
    margin-bottom: 10px;
  }
  .col-2.mt-22 {
    margin-top: 0;
  }
  .table-outer .table_page {
    padding: 0 12px;
  }
  .pm-0 {
    padding: 0 !important;
  }
}


/************Add Study Kit Modal***************************/

.w-auto{width: auto !important;}
.ant-input {
  height: 40px;
}
.pr-8{padding-right: 8px;}
.right-align .ant-form-item-control-input-content{display: flex; justify-content: end;}
.study-kit-row{ 
  align-items: flex-start;  column-gap: 8px;
}
.anticon-minus-circle{margin-top: 12px;   }
.study-kit-row .anticon-minus-circle svg{ width: 16px!important;height: 16px!important;}
.study-kit-row .anticon-minus-circle svg path{fill: #f83a2d!important;}
.Question-optional-row+.ant-form-item{display: none;}
.add-document-button-row .ant-form-item-control-input-content{display: flex; column-gap: 8px; align-items: flex-start;}


.anticon-plus,.anticon-minus-circle{    display: flex;
  justify-content: center;
  align-items: center;}

  .anticon.anticon-minus-circle.form-control{margin-top: 0;}
  .modal-body .ant-select-single .ant-select-selector {
    height: 40px !important;
}
.modal-body .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 40px !important;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
}
.modal-body .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,.modal-body .ant-select .ant-select-selection-item{  color: rgba(0, 0, 0, 0.70) !important;
  font-size: 14px !important;
  line-height: 1.5714285714285714;}
.modal-body .ant-select-single {
    height: 40px !important;
}
@media (max-width:992px) {

.study-kit-row {
  flex-wrap: wrap;
}
.study-kit-row .ant-form-item{width: 100%;}
.Question-optional-row{flex-wrap: wrap;    width: 100%;}
.Question-optional-row .pr-8 {
  padding-right: 0;
}
.anticon-minus-circle {
  margin-top: 0;
  margin-bottom: 24px;
  border-bottom: solid 1px #d9d9d9;
  width: 100%;
  padding-bottom: 24px;
}
.ant-select-single {
  width: 100% !important;
}
}
