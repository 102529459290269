/* footer  */
footer.footer {
  margin-left: 60px;
  font-size: 13px;
  color: #505d69;
  z-index: 960;
  background-color: var(--bs-white);
  box-shadow: 0px -2px 10px #70ab3a24;
  border-radius: 5px;
  height: 60px;
  padding: 20px calc(40px / 2);
}
.copyright a {
  color: currentColor;
}
.copyright {
  font-size: 13px;
}
@media (max-width: 992px) {
  footer.footer {
    margin-left: 0;
  }
}
