.edulan-icon {
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.down-arrow {
    width: 7px;
    height: 5px;
    background-image: url(../icons/down-arrow.svg);
}

.add-files {
    width: 14px;
    height: 15px;
    background-image: url(../icons/add-file.png);
    margin-top:2px;
    vertical-align: top;
}

.add-cate {
    width: 15px;
    height: 15px;
    background-image: url(../icons/add.png);
    margin-top:2px;
    vertical-align: top;
}


.edit-icon {
    width: 17px;
    height: 15px;
    background-image: url(../icons/edit-icon.svg);
}

.calendar-icon {
    width: 20px;
    height: 20px;
    background-image: url(../icons/calendar-icon.svg);
}

.add-customer {
    width: 24px;
    height: 14px;
    position: relative;
    top: 2px;
    background-image: url(../icons/add-customer.svg);
}

.delete-icon {
    width: 16px;
    height: 17px;
    background-image: url(../icons/delete.svg);
}

.eyes-icon {
    width: 18px;
    height: 18px;
    background-image: url(../icons/eyes.svg);
}
.plus-icon {
    width: 18px;
    height: 18px;
    background-image: url(../icons/plus.svg);
}
.minus-icon {
    width: 18px;
    height: 18px;
    background-image: url(../icons/minus.svg);
}